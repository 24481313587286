<template>
    <div>
        {{$t('notifications.title')}}
    </div>
</template>

<script>
export default {
    name: 'NodeprojectNotifications',
    components: {  },
    directives: {  },
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style scoped>

</style>